import React from 'react';
import { isAuthenticated, login, getIdToken } from "../utils/auth"
import Layout from '../components/Layout';
import uuid from 'react-uuid'
import { format } from 'date-fns'

const NotesPage = () => {

    const [noteTitle, setNoteTitle] = React.useState("");
    const [noteDescription, setNoteDescription] = React.useState("");

    const [editNoteId, setEditNoteId] = React.useState("");
    const [editNoteTitle, setEditNoteTitle] = React.useState("");
    const [editNoteDescription, setEditNoteDescription] = React.useState("");
    const [editNoteDone, setEditNoteDone] = React.useState(false);
    const [showEditForm, setShowEditForm] = React.useState(false);

    const [tableData, setTableData] = React.useState("");
    let tableDataTemp = [];

    const handleNoteTitleChange = (event) => {
        const value = event.target.value

        setNoteTitle(value)
    }

    const handleNoteDescriptionChange = (event) => {
        const value = event.target.value

        setNoteDescription(value)
    }

    const handleEditNoteTitleChange = (event) => {
        const value = event.target.value

        setEditNoteTitle(value)
    }

    const handleEditNoteDescriptionChange = (event) => {
        const value = event.target.value

        setEditNoteDescription(value)
    }

    const handleEditNoteDoneChange = (event) => {
        const value = event.target.checked

        setEditNoteDone(value)
    }

    const handleShowEditFormChange = (id, title, description, done) => {
        setEditNoteId(id);
        setEditNoteTitle(title)
        setEditNoteDescription(description);
        setEditNoteDone(done)
        setShowEditForm(true);
    }

    const handleAddNote = async () => {

        const token = getIdToken();

        const requestHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        });

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({ title: noteTitle, description: noteDescription }),
        };

        try {
            const response = await fetch(process.env.GATSBY_NOTES_URL, requestOptions);

            console.log(response);

            if (response.status === 200) {
                setNoteTitle('');
                setNoteDescription('');
                fetchNotes();
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleDeleteNote = async (id) => {

        const token = getIdToken();

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': 'Bearer ' + token }
        };

        let url = new URL(process.env.GATSBY_NOTES_URL);
        url.searchParams.append("id", id);

        try {
            const response = await fetch(url, requestOptions)
            if (response.status === 200) {
                await fetchNotes();
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const addDataToTableSource = (data) => {
        let tableRow = {
            id: data._id,
            title: data.title,
            description: data.description,
            created: format(new Date(data.created), 'yyyy-MM-dd HH:mm:ss'),
            updated: format(new Date(data.updated), 'yyyy-MM-dd HH:mm:ss'),
            done: data.done
        }

        tableDataTemp.push(tableRow);
    }

    const fetchNotes = async () => {

        const token = getIdToken();

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + token }
        };

        let response = await fetch(process.env.GATSBY_NOTES_URL, requestOptions);
        let responseJson = await response.json();

        responseJson.forEach(data => {
            addDataToTableSource(data);
        })

        setTableData(tableDataTemp);
    }

    const editNoteItem = async () => {

        const token = getIdToken();

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'PATCH',
            headers: { 'Authorization': 'Bearer ' + token }
        };

        let url = new URL(process.env.GATSBY_NOTES_URL);
        url.searchParams.append("id", editNoteId);
        url.searchParams.append("title", editNoteTitle);
        url.searchParams.append("done", editNoteDone);
        url.searchParams.append("description", editNoteDescription);

        try {
            const response = await fetch(url, requestOptions)
            if (response.status === 200) {
                setEditNoteId("");
                setEditNoteTitle("")
                setEditNoteDescription("");
                setEditNoteDone(false)
                setShowEditForm(false)
                await fetchNotes();
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const EditNotesItem = () => {
        return (
            <div className="row" key={uuid()} id="edit">
                <div className="col-12">
                    <h4>Edit Note</h4>
                </div>
                <div className="col-4 col-12-small">
                    <ul className="alt">
                        <li><label htmlFor="note-title">Note Title</label></li>
                        <li><input type="text" name="note-title" id="note-title" value={editNoteTitle} onChange={(event) => handleEditNoteTitleChange(event)} /></li>
                    </ul>
                </div>
                <div className="col-4 col-12-small">
                    <ul className="alt">
                        <li><label htmlFor="note-description">Description</label></li>
                        <li><textarea name="note-description" id="note-description" rows="3" value={editNoteDescription} onChange={(event) => handleEditNoteDescriptionChange(event)}></textarea></li>
                    </ul>
                </div>
                <div className="col-4 col-12-small">
                    <ul className="alt actions fit small">
                        <li><input type="checkbox" id="note-done" name="note-done" checked={editNoteDone} onChange={(event) => handleEditNoteDoneChange(event)} />
                            <label htmlFor="note-done">Done</label></li>
                        <li><a href="#read" className="button primary fit small" onClick={() => editNoteItem()}>Edit Item</a></li>
                    </ul>
                </div>
            </div>
        )
    }

    const NotesListTable = () => {
        return ((tableData !== undefined) && (tableData.length !== undefined) && tableData.length > 0 && (
            tableData.map((row) => (
                <div className="row" key={uuid()}>
                    <div className="col-12">
                        <h4>{row.title}</h4>
                    </div>
                    <div className="col-4 col-12-small">
                        <ul className="alt">
                            <li>{row.description}</li>
                        </ul>
                    </div>
                    <div className="col-4 col-12-small">
                        <ul className="alt">
                            <li>Done:  {row.done === true ? "True" : "False"}</li>
                            <li>Created:  {row.created}</li>
                            <li>Updated:  {row.updated}</li>
                        </ul>
                    </div>
                    <div className="col-4 col-12-small">
                        <ul className="alt actions fit small">
                            <li><a href="#edit" className="button primary fit small" onClick={() => handleShowEditFormChange(row.id, row.title, row.description, row.done)}>Edit</a></li>
                            <li><a href="#read" className="button fit small" onClick={() => handleDeleteNote(row.id)}>Delete</a></li>
                        </ul>
                    </div>
                </div>
            ))
        ))
    }

    if (!isAuthenticated()) {
        login()
        return <p>Redirecting to login...</p>
    }

    return (
        <Layout fullMenu>
            <section id="banner">
                <header>
                    <div className="inner">
                        <h2>Notes</h2>
                    </div>
                </header>
                <div className="wrapper" id="read">
                    <div className="inner">
                        <h3>Notes List</h3><br /><br />
                        <div className="col-12">
                            <ul className="actions">
                                <li><a href="#read" className="button primary" onClick={() => fetchNotes()}>Get Notes</a></li>
                            </ul>
                        </div>
                        {showEditForm && <EditNotesItem />}
                        <NotesListTable />
                    </div>
                </div>
                <div className="wrapper" id="add">
                    <div className="inner">
                        <h3>Add Note</h3><br /><br />
                        <div className="row gtr-uniform">
                            <div className="col-6 col-12-xsmall">
                                <label htmlFor="note-title">Note Title</label>
                                <input type="text" name="note-title" id="note-title" value={noteTitle} onChange={(event) => handleNoteTitleChange(event)} />
                            </div>
                            <div className="col-12">
                                <label htmlFor="note-description">Description</label>
                                <textarea name="note-description" id="note-description" rows="3" value={noteDescription} onChange={(event) => handleNoteDescriptionChange(event)}></textarea>
                            </div>
                            <div className="col-12">
                                <ul className="actions">
                                    <li><a href="#add" className="button primary" onClick={() => handleAddNote()}>Add Note</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout >
    )
};

export default NotesPage;